<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'application' })" />
        </div>
      </div>
      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier Capacité SI</p>
          <b-button v-if="editMode" variant="secondary" @click="editMode = !editMode"
            >Confirmer</b-button
          >
          <hr class="w-100" />
        </div>

        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group label-cols="12" label="Libellé" label-class="font-weight-bold">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Capacité de rattachement"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group label-cols="12" label="Niveau" label-class="font-weight-bold">
              <b-form-input type="number"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Nb Application"
              label-class="font-weight-bold"
            >
              <b-form-input type="number"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";

export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
  }),
  methods: {},
};
</script>

<style></style>
